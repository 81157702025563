.easy-edit-wrapper {
  padding: 5px 10px;
  background-color: lighten($primary, 42%);
  border: 1px dotted $primary;
}

.easy-edit-small.disabled > .easy-edit-wrapper {
  background-color: lighten($primary, 48%) !important;
  border-color: $gray-600;
}

.easy-edit-small > .easy-edit-inline-wrapper {
  width: 200px;
}

.easy-edit-small .easy-edit-component-wrapper {
  display: inline;
}
