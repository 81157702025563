html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
}

body {
  -webkit-font-smoothing: antialiased;
  background: #f5f6f7;
  font-family: Arial, Helvetica, sans-serif;
}
