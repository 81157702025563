@import "~react-toastify/dist/ReactToastify.css";
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import "~tui-calendar/dist/tui-calendar.css";
@import "~react-calendar/dist/Calendar.css";

@import "./Styles/reboot";

form .alert {
  margin-top: 20px;
}

.border-dotted {
  border-style: dashed !important;
}

.cursor-pointer {
  cursor: pointer;
}

button:focus,
.navbar-toggler:focus {
  outline: none;
}

.bg-hover-light:hover {
  background-color: $gray-300;
}

@import "./Components/CardDocumento/styles";
@import "./Styles/arrow";
@import "./Styles/scrollbar";
@import "./Styles/onOffSwitch";
@import "./Styles/easyEdit";
