$size: 10px;
.arrow {
  &::after,
  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    position: absolute;
  }
}

.arrow-bottom-left {
  @extend .arrow;
  &:before {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $gray-200;
    transform: rotateZ(135deg);
    bottom: -($size - 3);
    left: -2px;
  }
}

.arrow-bottom-left-danger {
  @extend .arrow-bottom-left;
  &:before {
    border-left: $size solid $danger;
  }
}

.arrow-bottom-left-primary {
  @extend .arrow-bottom-left;
  &:before {
    border-left: $size solid $primary;
  }
}
